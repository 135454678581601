import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage
import { v4 as uuid } from "uuid";

import * as types from "./types";
import * as dbSelector from "../../../state/dbSelector";
import { getGeneric, postGeneric } from "../../../api";

//Protocols
export const getAgencies = () => async (dispatch) => {
  dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: true });
  try {
    const snapShot = await firebase
      .firestore()
      .collection(dbSelector.AGENCIES)
      .get();
    let results = null;

    //https://firebase.google.com/docs/firestore/query-data/get-data#get_a_document
    if (!snapShot.empty) {
      results = snapShot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
    }

    dispatch({ type: types.MANAGEAGENCIES_GET_AGENCIES, payload: results });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: false });
  }
};

export const setAgencyId = (id) => async (dispatch) => {
  dispatch({ type: types.MANAGEAGENCIES_SET_AGENCY_ID, payload: id });
};

export const setProtocolNew = (value) => async (dispatch) => {
  dispatch({ type: types.MANAGEAGENCIES_SET_PROTOCOLNEW, payload: value });
};

export const setProtocolEdit = (value, row) => async (dispatch) => {
  dispatch({
    type: types.MANAGEAGENCIES_SET_PROTOCOLEDIT,
    payload: { value, row },
  });
};

export const getProtocolFiles = (agencyId) => async (dispatch) => {
  dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: true });
  try {
    let results = [];
    const snapShot = await firebase
      .firestore()
      .collection(dbSelector.AGENCIES)
      .doc(agencyId)
      .collection(dbSelector.FILES)
      .get();

    if (!snapShot.exists) {
      results = snapShot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
    }

    dispatch({
      type: types.MANAGEAGENCIES_GET_AGENCIES_FILES,
      payload: results,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: false });
  }
};

export const editProtocol = (agencyId, data) => async (dispatch) => {
  dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: true });
  try {
    const { id, state, category } = data;
    //firestore
    await firebase
      .firestore()
      .collection(dbSelector.AGENCIES)
      .doc(agencyId)
      .collection(dbSelector.FILES)
      .doc(id)
      .update({ state, category });
    await Promise.all([dispatch(getProtocolFiles(agencyId))]);
    dispatch({ type: types.MANAGEAGENCIES_SET_PROTOCOLEDIT, payload: false });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: false });
  }
};

export const addProtocol = (agencyId, file, data) => async (dispatch) => {
  dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: true });
  try {
    const id = uuid();
    const storageRef = firebase.storage().ref(dbSelector.FILES).child(id);
    await storageRef.put(file);
    const url = await storageRef.getDownloadURL();

    //firestore
    await firebase
      .firestore()
      .collection(dbSelector.AGENCIES)
      .doc(agencyId)
      .collection(dbSelector.FILES)
      .doc(id)
      .set({
        downloadUrl: url,
        fileName: file.name,
        state: data.State,
        category: data.Category,
        storagePath: `northcarolina/${id}`,
      });
    await Promise.all([dispatch(getProtocolFiles(agencyId))]);
    dispatch({ type: types.MANAGEAGENCIES_SET_PROTOCOLNEW, payload: false });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: false });
  }
};

export const deleteProtocol = (agencyId, fileId) => async (dispatch) => {
  dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: true });
  try {
    try {
      const storageRef = firebase.storage().ref(dbSelector.FILES).child(fileId);
      await storageRef.delete();
    } catch (error) {
      //do nothing
      //https://firebase.google.com/docs/storage/web/handle-errors
    }

    await firebase
      .firestore()
      .collection(dbSelector.AGENCIES)
      .doc(agencyId)
      .collection(dbSelector.FILES)
      .doc(fileId)
      .delete();

    await Promise.all([dispatch(getProtocolFiles(agencyId))]);
    dispatch({ type: types.MANAGEAGENCIES_SET_PROTOCOLEDIT, payload: false });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: false });
  }
};

//Users
export const addUser = ({ displayName, password, email, role }) => async (
  dispatch,
) => {
  dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: true });
  try {
    // console.log(displayName);
    await postGeneric("/api/users", {
      displayName,
      password,
      email,
      role,
    });
    // dispatch({ type: types.MANAGEAGENCIES_ADD, payload: null });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: false });
  }
};

export const getAllUsers = () => async (dispatch) => {
  const response = await getGeneric("/api/users");
  console.log(response);
};

//Agency
export const addAgency = (data) => async (dispatch) => {
  dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: true });
  try {
    const id = uuid();

    //firestore
    await firebase
      .firestore()
      .collection(dbSelector.AGENCIES)
      .doc(id)
      .set(data);

    await Promise.all([dispatch(getAgencies())]);
    dispatch({ type: types.MANAGEAGENCIES_SET_PROTOCOLNEW, payload: false });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: false });
  }
};

export const updateAgency = (data, agencyId) => async (dispatch) => {
  dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: true });
  try {
    //firestore
    await firebase
      .firestore()
      .collection(dbSelector.AGENCIES)
      .doc(agencyId)
      .update(data);

    await Promise.all([dispatch(getAgencies())]);
    dispatch({ type: types.MANAGEAGENCIES_SET_PROTOCOLNEW, payload: false });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: types.MANAGEAGENCIES_FETCHING, payload: false });
  }
};
