import firebase from "firebase/app";
import "firebase/firestore";
import { v4 as uuid } from "uuid";
import { reset } from "redux-form";
import * as types from "./types";
import * as dbSelector from "../../../state/dbSelector";
import { CONTACT_FORM } from "../../../common/FormEnum";

//Protocols
export const addContact = (values) => async (dispatch) => {
  dispatch({ type: types.CONTACT_FETCHING, payload: true });
  try {
    const id = uuid();

    await firebase.firestore().collection(dbSelector.CONTACT).doc(id).set({
      name: values.name,
      email: values.email,
      agencyName: values.agencyName,
      titlePosition: values.titlePosition,
      onBehalfAgency: values.onBehalfAgency,
      message: values.message, //sanitize
      dateSubmitted: new Date(),
      messageType: values.messageType,
    });

    dispatch({
      type: types.CONTACT_SUCCESS,
      payload: "Thank you! Your submission has been sent.",
    });

    dispatch(reset(CONTACT_FORM));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: types.CONTACT_FETCHING, payload: false });
  }
};

export const clearError = () => async (dispatch) => {
  try {
    dispatch({ type: types.CONTACT_ERROR_CLEAR, payload: null });
  } catch (e) {
    console.error("clearError", e);
  }
};

export const clearSuccess = () => async (dispatch) => {
  try {
    dispatch({ type: types.CONTACT_SUCCESS_CLEAR, payload: null });
  } catch (e) {
    console.error("clearSuccess", e);
  }
};
