import React, { Component } from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";

class YesNoDialog extends Component {
  static propTypes = {
    title: PropTypes.string,
    question: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.node,
    ]),
    yesCallback: PropTypes.func,
    noCallback: PropTypes.func,
    cancelCallback: PropTypes.func,
    showDialog: PropTypes.bool.isRequired,
    isSavePrompt: PropTypes.bool,
    showCancel: PropTypes.bool,
  };

  static defaultProps = {
    yesCallback: () => {},
    noCallback: () => {},
    cancelCallback: () => {},
  };

  render() {
    let { title, question } = this.props;
    const {
      noCallback,
      yesCallback,
      cancelCallback,
      showDialog,
      isSavePrompt,
      showCancel,
    } = this.props;
    let { noText = "No", yesText = "Yes" } = this.props;

    if (isSavePrompt) {
      title = "Un-saved changes detected";
      question =
        "Are you sure you want to close this window and lose any unsaved changes?";

      noText = "No, return to edited page.";
      yesText = "Yes, discard changes";
    }

    return (
      <Modal size="small" open={showDialog}>
        <Header icon="question circle" content={title} />
        <Modal.Content>
          <strong>
            {typeof question === "function" ? question() : question}
          </strong>
        </Modal.Content>
        <Modal.Actions>
          {showCancel ? (
            <Button basic className="pull-left" onClick={cancelCallback}>
              <strong>Cancel</strong>
            </Button>
          ) : undefined}
          <Button basic onClick={noCallback}>
            <Icon name="remove" /> <strong>{noText}</strong>
          </Button>
          <Button primary onClick={yesCallback}>
            <Icon className={"icon--white"} name="checkmark" /> {yesText}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export { YesNoDialog };
