import React, { Component } from "react";
import { Button, Grid, Form, GridColumn } from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import ReCAPTCHA from "react-google-recaptcha";
import { Label } from "semantic-ui-react";
import { CONTACT_FORM } from "../../../common/FormEnum";
import { ContactEnum as Fields } from "../../../common/FieldEnum";
import {
  InputField,
  ToggleField,
  TextAreaField,
  DropdownField,
} from "../../../common/SemanticReduxFormWrapper";

import { contactType } from "../../../common/constants";

class ContactContainer extends Component {
  renderRecaptchaField(field) {
    const {
      meta: { touched, error },
    } = field;
    return (
      <div>
        <ReCAPTCHA
          sitekey="6LftZTgbAAAAAEGdar6ePbWQ23C9xhTvSmKaHpaw"
          onChange={field.input.onChange}
        />
        {touched && error ? (
          <Label basic color="red" pointing>
            {error}
          </Label>
        ) : null}
      </div>
    );
  }

  render() {
    const { handleSubmit, loading } = this.props;

    return (
      <Form onSubmit={handleSubmit} name={CONTACT_FORM}>
        <div style={{ marginTop: "25px" }} />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.MessageType}
                component={DropdownField}
                placeholder="Select a message type"
                fluid
                search
                selection
                options={contactType}
                label="Message Type"
                closeOnEscape
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.Name}
                component={InputField}
                maxLength={200}
                label="Name"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.Email}
                component={InputField}
                maxLength={200}
                label="Email"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.AgencyName}
                component={InputField}
                maxLength={200}
                label="Agency Name"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.TitlePosition}
                component={InputField}
                // type="file"
                maxLength={200}
                label="Title at the Agency"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.OnBehalfAgency}
                component={ToggleField}
                maxLength={200}
                label="Message is on behalf of the agency"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.Message}
                component={TextAreaField}
                // type="file"
                maxLength={4000}
                label="Message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {this.props.submitFailed && this.props.error}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field name="recaptcha" component={this.renderRecaptchaField} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column>
              <Button type="submit" loading={loading}>
                Save
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

const validate = (values, props) => {
  const { recaptcha } = values;
  const errors = {};

  if (!values[Fields.MessageType]) {
    errors[Fields.MessageType] = "Required";
  }
  if (!values[Fields.Name] || values[Fields.Name].length === 0) {
    errors[Fields.Name] = "Required";
  }
  if (!values[Fields.Email] || values[Fields.Email].length === 0) {
    errors[Fields.Email] = "Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[Fields.Email])
  ) {
    errors[Fields.Email] = "Invalid email address";
  }

  if (!values[Fields.AgencyName] || values[Fields.AgencyName].length === 0) {
    errors[Fields.AgencyName] = "Required";
  }
  if (
    !values[Fields.TitlePosition] ||
    values[Fields.TitlePosition].length === 0
  ) {
    errors[Fields.TitlePosition] = "Required";
  }
  if (!values[Fields.Message] || values[Fields.Message].length === 0) {
    errors[Fields.Message] = "Required";
  }

  if (!recaptcha) {
    errors.recaptcha = "reCAPTCHA required.";
  }

  return errors;
};

const ContactForm = reduxForm({
  validate,
  form: CONTACT_FORM,
  enableReinitialize: true,
})(ContactContainer);

export { ContactForm };
