import React, { Component } from "react";
import { connect } from "react-redux";
import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";
import { Sidebar, Menu, Icon, Segment, Container } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

import { LogInOutButton } from "../layout/LogInOutButton";
import { logOut } from "../../pages/login/state/actions";
import { PAGES } from "../../common/constants";

const { Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 5000,
    computer: 7000,
  },
});

class MobileContainer extends Component {
  state = { activeItem: "" };

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    this.handleSidebarHide();
  };

  renderLogIn() {
    const { authToken } = this.props;
    const { activeItem } = this.state;

    if (authToken) {
      return null;
    }

    return (
      <Menu.Item
        name={`${PAGES.LOGIN}`}
        as={NavLink}
        to={`/${PAGES.LOGIN}`}
        active={activeItem === PAGES.LOGIN}
        onClick={this.handleItemClick}
      >
        Log in
      </Menu.Item>
    );
  }

  render() {
    const { children } = this.props;
    const { sidebarOpened, activeItem } = this.state;

    return (
      <Media as={Sidebar.Pushable} at="mobile">
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="overlay"
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item
              active={activeItem === PAGES.HOME}
              name={PAGES.HOME}
              as={NavLink}
              to="/"
              onClick={this.handleItemClick}
            >
              Home
            </Menu.Item>

            {this.renderLogIn()}

            <Menu.Item
              name={PAGES.MANAGEAGENCIES}
              as={NavLink}
              to={`/${PAGES.MANAGEAGENCIES}`}
              active={activeItem === PAGES.MANAGEAGENCIES}
              onClick={this.handleItemClick}
            >
              Manage agencies
            </Menu.Item>
            <Menu.Item
              name={PAGES.PRIVACYPOLICY}
              as={NavLink}
              to={`/${PAGES.PRIVACYPOLICY}`}
              active={activeItem === PAGES.PRIVACYPOLICY}
              onClick={this.handleItemClick}
            >
              Privacy Policy
            </Menu.Item>
            <Menu.Item
              name={PAGES.TERMSCONDITIONS}
              as={NavLink}
              to={`/${PAGES.TERMSCONDITIONS}`}
              active={activeItem === PAGES.TERMSCONDITIONS}
              onClick={this.handleItemClick}
            >
              Terms &amp; Conditions
            </Menu.Item>
            <Menu.Item
              name={PAGES.CONTACT}
              as={NavLink}
              to={`/${PAGES.CONTACT}`}
              active={activeItem === PAGES.CONTACT}
              onClick={this.handleItemClick}
            >
              Contact
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment inverted textAlign="center" vertical>
              <Container>
                <Menu inverted pointing secondary size="large">
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name="sidebar" />
                  </Menu.Item>
                  <Menu.Item>EMS Resources</Menu.Item>
                  <Menu.Item position="right">
                    <LogInOutButton />
                  </Menu.Item>
                </Menu>
              </Container>
            </Segment>
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = (state) => {
  return { authToken: state.LoginPageState.token };
};

export const Mobile = connect(mapStateToProps, { logOut })(MobileContainer);
