import axios from "axios";
import { LOCALSTORAGEKEY } from "../common/constants";

const setToken = () => {
  const idToken = localStorage.getItem(LOCALSTORAGEKEY.TOKEN);
  axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
};

export const postGeneric = (resourceName, data) => {
  const urlSegment = `${resourceName}`;

  setToken();

  return new Promise((resolve, reject) => {
    axios
      .post(urlSegment, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getGeneric = (resourceName, params = {}) => {
  const urlSegment = `${resourceName}`;

  setToken();

  return new Promise((resolve, reject) => {
    axios
      .get(urlSegment, { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getGenericWithToken = (resourceName, token, params = {}) => {
  const urlSegment = `${resourceName}`;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return new Promise((resolve, reject) => {
    axios
      .get(urlSegment, { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
