import React, { Component } from "react";
import { Container, Button } from "semantic-ui-react";

class HomePageContainer extends Component {
  render() {
    return <Container></Container>;
  }
}

export const HomePage = HomePageContainer;
