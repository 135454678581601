export const MANAGEAGENCIES_ADD = "MANAGEAGENCIES_ADD";
export const MANAGEAGENCIES_GET_AGENCIES = "MANAGEAGENCIES_GET_AGENCIES";
export const MANAGEAGENCIES_GET_AGENCIES_FILES =
  "MANAGEAGENCIES_GET_AGENCIES_FILES";
export const MANAGEAGENCIES_FETCHING = "MANAGEAGENCIES_FETCHING";
export const MANAGEAGENCIES_ERROR = "MANAGEAGENCIES_ERROR";
export const MANAGEAGENCIES_ERROR_CLEAR = "MANAGEAGENCIES_ERROR_CLEAR";
export const MANAGEAGENCIES_SET_AGENCY_ID = "MANAGEAGENCIES_SET_AGENCY_ID";
export const MANAGEAGENCIES_SET_PROTOCOLEDIT =
  "MANAGEAGENCIES_SET_PROTOCOLEDIT";
export const MANAGEAGENCIES_SET_PROTOCOLNEW = "MANAGEAGENCIES_SET_PROTOCOLNEW";
