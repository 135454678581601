import * as types from "./types";

const INITIAL_STATE = {
  error: null,
  fetching: false,
  success: null,
};

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case types.CONTACT_ERROR:
      return { ...state, error: payload };

    case types.CONTACT_ERROR_CLEAR:
      return { ...state, error: payload };

    case types.CONTACT_SUCCESS:
      return { ...state, success: payload };

    case types.CONTACT_SUCCESS_CLEAR:
      return { ...state, success: payload };

    case types.CONTACT_FETCHING:
      return { ...state, fetching: payload };

    default:
      return state;
  }
};
