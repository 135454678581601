import React, { Component } from "react";
import { Button, Grid, Form } from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import { MANAGE_FILES_NEW_FORM } from "../../../common/FormEnum";
import { ManageFilesNewEnum as Fields } from "../../../common/FieldEnum";
import {
  UploadField,
  InputField,
  DropdownField,
} from "../../../common/SemanticReduxFormWrapper";
import { states as stateOptions } from "../../../common/constants";

class ManageFilesNewContainer extends Component {
  goBack = () => {
    this.props.setProtocolNew(false);
  };

  render() {
    const { handleSubmit, loading, submitting, pristine } = this.props;

    return (
      <Form onSubmit={handleSubmit} name={MANAGE_FILES_NEW_FORM}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.State}
                component={DropdownField}
                placeholder="Select a state"
                fluid
                search
                selection
                options={stateOptions}
                label="State"
                closeOnEscape
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.Category}
                component={InputField}
                // type="file"
                maxLength={200}
                label="Category"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.File}
                component={UploadField}
                type="file"
                maxLength={500}
                label="File"
                accept="application/pdf"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {this.props.submitFailed && this.props.error}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column>
              <Button
                type="submit"
                loading={loading}
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button type="button" onClick={this.goBack}>
                Cancel
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  if (!values[Fields.File] || values[Fields.File].length === 0) {
    errors[Fields.File] = "Please choose a file";
  } else if (values[Fields.File][0].size > 50 * 1024 * 1024) {
    errors[Fields.File] = "File size must be less than 50MB.";
  } else if (!values[Fields.State]) {
    errors[Fields.State] = "Please choose a state";
  }

  return errors;
};

const ManageFilesNew = reduxForm({
  validate,
  form: MANAGE_FILES_NEW_FORM,
  enableReinitialize: true,
})(ManageFilesNewContainer);

export { ManageFilesNew };
