import React, { Component } from "react";
import { connect } from "react-redux";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import "firebase/auth";
import { Container, Grid, Header, Image, Button } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Logo from "../../styles/logos/star-of-life-png-25.png";
import { loginSuccess, clearError } from "./state/actions";

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: "popup", //"redirect",
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
};

class LoginPageContainer extends Component {
  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      let id_token = "";
      if (user) {
        user
          .getIdToken()
          .then(function (idToken) {
            id_token = idToken;
          })
          .then(() => {
            this.props.loginSuccess(user, user.refreshToken, id_token);
          });
      }
    });
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  callClearError = () => {
    this.props.clearError();
  };

  componentDidUpdate(prevProps) {
    const { error } = this.props;

    if (error && !prevProps.error) {
      toast.error(error, { onClose: this.callClearError() });
    }
  }

  getReferer = () => {
    const { location } = this.props;

    return location.state?.referer ? location.state.referer : "/";
  };

  renderRedirect() {
    return <Redirect to={this.getReferer()} />;
  }

  renderLogin() {
    return (
      <Container>
        <Grid textAlign="center" verticalAlign="middle" margin-top="1rem">
          <Grid.Row>
            <Grid.Column>
              <Header as="h3" textAlign="center">
                <Image src={Logo} /> EMS Resources Admin
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ToastContainer position="top-center" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }

  render() {
    if (this.props.user) {
      return this.renderRedirect();
    }

    return this.renderLogin();
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.LoginPageState.user,
    error: state.LoginPageState.error,
  };
};

export const LoginPage = connect(mapStateToProps, { loginSuccess, clearError })(
  LoginPageContainer,
);
