import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Grid, Header, Image, Button } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addContact, clearError, clearSuccess } from "./state/actions";
import { ContactForm } from "./components/ContactForm";
import { ContactEnum as Fields } from "../../common/FieldEnum";

//todo:
// clear form after submit
// toast letting the user know the submit was successful
// add dropdown with the type of request

class ContactPageContainer extends Component {
  callClearError = () => {
    this.props.clearError();
  };

  callClearSuccess = () => {
    this.props.clearSuccess();
  };

  componentDidUpdate(prevProps) {
    const { error, success } = this.props;
    if (error && !prevProps.error) {
      toast.error(error, { onClose: this.callClearError() });
      window.scrollTo(0, 0);
    }

    if (success && !prevProps.success) {
      toast.success(success, { onClose: this.callClearSuccess() });
      window.scrollTo(0, 0);
    }
  }

  handleSubmit = (formValues) => {
    this.props.addContact(formValues);
  };

  render() {
    return (
      <Container>
        <ContactForm
          onSubmit={this.handleSubmit}
          loading={this.props.fetching}
          initialValues={{
            [Fields.Name]: "",
            [Fields.Email]: "",
            [Fields.AgencyName]: "",
            [Fields.TitlePosition]: "",
            [Fields.OnBehalfAgency]: false,
            [Fields.Message]: "",
          }}
        />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <ToastContainer position="top-center" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: state.ContactPageState.fetching,
    error: state.ContactPageState.error,
    success: state.ContactPageState.success,
  };
};

export const ContactPage = connect(mapStateToProps, {
  addContact,
  clearError,
  clearSuccess,
})(ContactPageContainer);
