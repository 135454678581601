import "./App.css";
import "semantic-ui-css/semantic.min.css";
import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import firebase from "firebase/app";
import axios from "axios";

import { HomePage } from "../src/pages/home/HomePage";
import { LoginPage } from "./pages/login/LoginPage";
import { ManageAgenciesPage } from "./pages/manageagencies/ManageAgenciesPage";
import { PrivacyPolicyPage } from "./pages/privacypolicy/PrivacyPolicyPage";
import { TermsConditionsPage } from "./pages/termsconditions/TermsConditionsPage";
import { ContactPage } from "./pages/contact/ContactPage";

import PrivateRoute from "./components/PrivateRoute";
import store from "./store";
import { Mobile } from "../src/components/layout/MobileContainer"; //Layout
import { config } from "./components/firebase/config";
import { ContextMenuDisplay } from "./common/contextmenu/ContextMenuDisplay";
import { PAGES } from "./common/constants";

export default class App extends Component {
  componentDidMount() {
    //Axios Global Config
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.get["Accept"] = "application/json";
    axios.defaults.headers.post["Accept"] = "application/json";
  }

  render() {
    console.log("app");

    if (firebase.apps.length === 0) {
      console.log("Initialize Firebase");
      firebase.initializeApp(config);
    }

    return (
      <Fragment>
        <ContextMenuDisplay />
        <Provider store={store}>
          <div className="App">
            <Mobile>
              <Switch>
                <Route path="/" exact component={HomePage} />
                <Route path={`/${PAGES.LOGIN}`} component={LoginPage} />
                <PrivateRoute
                  path={`/${PAGES.MANAGEAGENCIES}`}
                  component={ManageAgenciesPage}
                />
                <Route
                  path={`/${PAGES.PRIVACYPOLICY}`}
                  component={PrivacyPolicyPage}
                />
                <Route
                  path={`/${PAGES.TERMSCONDITIONS}`}
                  component={TermsConditionsPage}
                />
                <Route path={`/${PAGES.CONTACT}`} component={ContactPage} />
              </Switch>
            </Mobile>
          </div>
        </Provider>
      </Fragment>
    );
  }
}
