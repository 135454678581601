import * as types from "./types";
import { LOCALSTORAGEKEY } from "../../../common/constants";

const INITIAL_STATE = {
  user: null,
  token:
    localStorage.getItem(LOCALSTORAGEKEY.TOKEN) === null
      ? ""
      : localStorage.getItem(LOCALSTORAGEKEY.TOKEN),
  refreshToken: "",
  error: null,
  fetching: false,
  isSignedIn: false,
};

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case types.LOGIN_AUTH:
      localStorage.setItem(LOCALSTORAGEKEY.TOKEN, payload.token);
      return {
        ...state,
        user: {
          uid: payload.user.uid,
          displayName: payload.user.displayName,
          email: payload.user.email,
          agencyIds: payload.agencyIds.split(","),
          role: payload.role,
        },
        token: payload.token,
        refreshToken: payload.refreshToken,
        isSignedIn: true,
      };

    case types.LOGIN_CLEAR:
      localStorage.removeItem(LOCALSTORAGEKEY.TOKEN);
      return { ...INITIAL_STATE };

    case types.LOGIN_ERROR:
      return { ...state, error: payload };

    case types.LOGIN_FETCHING:
      return { ...state, fetching: payload };

    case types.LOGIN_ERROR_CLEAR:
      return { ...state, error: payload };

    default:
      return state;
  }
};
