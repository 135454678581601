import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import * as types from "./types";
import LoginPageReducer from "../pages/login/state/reducer";
import ManageAgenciesReducer from "../pages/manageagencies/state/reducer";
import ContactPageReducer from "../pages/contact/state/reducer";

const appReducer = combineReducers({
  form: formReducer,
  LoginPageState: LoginPageReducer,
  ManageAgenciesState: ManageAgenciesReducer,
  ContactPageState: ContactPageReducer,
});

//https://medium.com/mfec/clear-state-in-redux-store-f846ec5fc0c0
const rootReducer = (state, action) => {
  if (action.type === types.SIGNOUT_REQUEST) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
