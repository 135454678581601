import React from "react";
import { Icon } from "semantic-ui-react";
import EventManager from "./ContextMenuEventManager";

export const ContextMenuEvent = {
  SHOW_GRID_MENU: "SHOW_GRID_MENU",
  SHOW_MENU: "SHOW_MENU",
  CLOSE_MENU: "CLOSE_MENU",
};

const openGridContextMenu = ({ gridProps, options, target }) => {
  EventManager.emit(ContextMenuEvent.SHOW_GRID_MENU, {
    gridProps,
    options,
    target,
  });
};

const allDisabled = ({ data, options }) => {
  return options.every(
    (option) => !!(option.disabledWhen && option.disabledWhen(data)),
  );
};

export const GridContextMenu = ({ gridProps, options }) => {
  if (allDisabled({ data: gridProps.original, options })) {
    //don't show the cog if all the options are disabled
    return null;
  }

  return (
    <Icon
      name={"ellipsis horizontal"}
      className={"ellipsis horizontal large"}
      onClick={(event) => {
        const { target } = event;

        openGridContextMenu({
          gridProps,
          options,
          target,
        });
      }}
    />
  );
};
