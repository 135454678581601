export const ManageFilesNewEnum = {
  FileName: "FileName",
  File: "File",
  Category: "Category",
  State: "State",
};

export const ManageFilesEditEnum = {
  Category: "Category",
  State: "State",
};

export const ManageAgencyEnum = {
  State: "state",
  County: "county",
  Name: "name",
  StateWide: "stateWide",
  Enabled: "enabled",
};

export const ContactEnum = {
  Name: "name",
  Email: "email",
  AgencyName: "agencyName",
  TitlePosition: "titlePosition",
  OnBehalfAgency: "onBehalfAgency",
  Message: "message",
  MessageType: "messageType",
};
