import React, { Component } from "react";
import { Container, Tab, Dropdown, Grid, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { getAgencies, setAgencyId } from "./state/actions";
import { ManageFiles } from "./tabs/ManageFiles";
import { ManageFilesNew } from "./tabs/ManageFilesNew";
import { ManageFilesEdit } from "./tabs/ManageFilesEdit";
import { ManageAgency } from "./tabs/ManageAgency";
import {
  addProtocol,
  setProtocolNew,
  setProtocolEdit,
  getProtocolFiles,
  deleteProtocol,
  editProtocol,
  addAgency,
  updateAgency,
} from "./state/actions";
import { ManageFilesEditEnum as Fields } from "../../common/FieldEnum";
import { ManageAgencyEnum as AgencyFields } from "../../common/FieldEnum";
import { TabEnum } from "./tabs/TabEnum";
import { ROLES } from "../../common/constants";

class ManageAgenciesPageContainer extends Component {
  state = { activeIndex: TabEnum.Agency };

  componentDidMount() {
    this.props.getAgencies();
  }

  setAgencyId = (e, data) => {
    this.props.setAgencyId(data.value);
    this.setState({ activeIndex: TabEnum.Protocols });
  };

  createAgency = () => {
    this.props.setAgencyId("");
    this.setState({ activeIndex: TabEnum.Agency });
  };

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex });
  };

  setProtocolEdit = (value, row) => {
    this.props.setProtocolEdit(value, row);
  };

  setProtocolNew = (value) => {
    this.props.setProtocolNew(value);
  };

  handleSubmitNew = (formValues) => {
    this.props.addProtocol(this.props.agencyId, formValues.File[0], formValues);
  };

  handleSubmitEdit = (formValues) => {
    const { protocolEditRow, agencyId } = this.props;

    const data = {
      id: protocolEditRow.id,
      category: formValues.Category,
      state: formValues.State,
    };

    this.props.editProtocol(agencyId, data);
  };

  handleSubmitAgency = (formValues) => {
    const { agencyId } = this.props;

    if (agencyId) {
      this.props.updateAgency(formValues, agencyId);
    } else {
      this.props.addAgency(formValues);
    }
  };

  componentDidUpdate(prevProps) {
    const { agencyId } = this.props;

    if (agencyId && prevProps.agencyId !== agencyId) {
      this.props.getProtocolFiles(agencyId);
    }
  }

  getAgencyInitialValues = () => {
    const { agencyId, agencies } = this.props;
    const agency = agencies
      ? agencies.find((x) => x.id === agencyId)
      : undefined;

    let stateWide = false;
    if (agency && agency.stateWide) {
      stateWide = agency.stateWide;
    }

    let enabled = false;
    if (agency && agency.enabled) {
      enabled = agency.enabled;
    }

    return {
      [AgencyFields.County]: agency ? agency.county : "",
      [AgencyFields.State]: agency ? agency.state : "",
      [AgencyFields.StateWide]: stateWide,
      [AgencyFields.Name]: agency ? agency.name : "",
      [AgencyFields.Enabled]: enabled,
    };
  };

  panes = () => {
    const protocolMenuItem = {
      key: "protocols",
      icon: "file",
      content: "Protocols",
    };
    const results = [
      {
        menuItem: { key: "users", icon: "users", content: "Users" },
        render: () => (
          <Tab.Pane>
            <div>Users Content</div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: { key: "agency", icon: "building", content: "Agency" },
        render: () => (
          <Tab.Pane>
            <ManageAgency
              onSubmit={this.handleSubmitAgency}
              loading={fetching}
              initialValues={this.getAgencyInitialValues()}
              disabled={this.props.user?.role === ROLES.ADMIN ? false : true}
            />
          </Tab.Pane>
        ),
      },
    ];

    const {
      fetching,
      protocolFiles,
      deleteProtocol,
      agencyId,
      protocolEditRow,
      protocolNew,
      protocolEdit,
      agencies,
    } = this.props;

    const agency = agencies
      ? agencies.find((x) => x.id === agencyId)
      : undefined;

    if (protocolNew) {
      results.unshift({
        menuItem: protocolMenuItem,
        render: () => (
          <Tab.Pane>
            <ManageFilesNew
              setProtocolNew={this.setProtocolNew}
              onSubmit={this.handleSubmitNew}
              loading={fetching}
              initialValues={{
                [Fields.Category]: "",
                [Fields.State]: agency ? agency.state : "",
              }}
            />
          </Tab.Pane>
        ),
      });
    } else if (protocolEdit) {
      results.unshift({
        menuItem: protocolMenuItem,
        render: () => (
          <Tab.Pane>
            <ManageFilesEdit
              setProtocolEdit={this.setProtocolEdit}
              data={protocolFiles}
              initialValues={{
                [Fields.Category]: protocolEditRow.category,
                [Fields.State]: protocolEditRow.state,
              }}
              onSubmit={this.handleSubmitEdit}
              loading={fetching}
            />
          </Tab.Pane>
        ),
      });
    } else if (agencyId) {
      results.unshift({
        menuItem: protocolMenuItem,
        render: () => (
          <Tab.Pane>
            <ManageFiles
              data={protocolFiles}
              setProtocolEdit={this.setProtocolEdit}
              setProtocolNew={this.setProtocolNew}
              deleteProtocol={deleteProtocol}
              agencyId={agencyId}
              loading={fetching}
            />
          </Tab.Pane>
        ),
      });
    } else {
      results.unshift({
        menuItem: protocolMenuItem,
        render: () => (
          <Tab.Pane>
            <div>Select Agency</div>
          </Tab.Pane>
        ),
      });
    }

    return results;
  };

  renderTabs() {
    const { activeIndex } = this.state;

    return (
      <Tab
        activeIndex={activeIndex}
        onTabChange={this.handleTabChange}
        panes={this.panes()}
      />
    );
  }

  agencyOptions = () => {
    const { agencies, user } = this.props;

    let filterAgencies = agencies;

    if (user?.role === ROLES.MANAGER) {
      filterAgencies = (agencies && agencies.length > 1 ? agencies : []).filter(
        (item) => {
          return !user.agencyIds.includes(item.id);
        },
      );
    }

    const agencyOptions = (
      filterAgencies && filterAgencies.length > 1 ? [] : []
    ).concat(
      filterAgencies
        ? filterAgencies.map((agency, i) => ({
            value: agency.id,
            key: i,
            text: `${agency.state} -  ${agency.name}`,
          }))
        : [],
    );

    return agencyOptions;
  };

  render() {
    const { fetching, agencyId, user } = this.props;

    return (
      <Container>
        <Grid>
          <Grid.Row centered>
            <Grid.Column width={12}>
              <label>Agency Search</label>
              <Dropdown
                placeholder="Select Agency"
                fluid
                search
                selection
                options={this.agencyOptions()}
                onChange={this.setAgencyId}
                loading={fetching}
                closeOnEscape
                value={agencyId}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width={12}>
              <Button
                onClick={this.createAgency}
                disabled={user?.role === ROLES.ADMIN ? false : true}
              >
                Create New Agency
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{this.renderTabs()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    agencies: state.ManageAgenciesState.agencies,
    agencyId: state.ManageAgenciesState.agencyId,
    fetching: state.ManageAgenciesState.fetching,
    protocolEdit: state.ManageAgenciesState.protocolEdit,
    protocolNew: state.ManageAgenciesState.protocolNew,
    protocolFiles: state.ManageAgenciesState.protocolFiles,
    protocolEditRow: state.ManageAgenciesState.protocolEditRow,
    user: state.LoginPageState.user,
  };
};

export const ManageAgenciesPage = connect(mapStateToProps, {
  getAgencies,
  setAgencyId,
  addProtocol,
  setProtocolNew,
  setProtocolEdit,
  getProtocolFiles,
  deleteProtocol,
  editProtocol,
  addAgency,
  updateAgency,
})(ManageAgenciesPageContainer);
