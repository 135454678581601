import React, { Component } from "react";
import { Button, Grid, Form } from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import { MANAGE_AGENCY_FORM } from "../../../common/FormEnum";
import { ManageAgencyEnum as Fields } from "../../../common/FieldEnum";
import {
  InputField,
  DropdownField,
  ToggleField,
} from "../../../common/SemanticReduxFormWrapper";
import { states as stateOptions } from "../../../common/constants";

class ManageAgencyContainer extends Component {
  render() {
    const { handleSubmit, loading, submitting, pristine, disabled } =
      this.props;

    return (
      <Form onSubmit={handleSubmit} name={MANAGE_AGENCY_FORM}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.Name}
                component={InputField}
                maxLength={200}
                label="Agency Name"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.State}
                component={DropdownField}
                placeholder="Select a state"
                fluid
                search
                selection
                options={stateOptions}
                label="State"
                closeOnEscape
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.County}
                component={InputField}
                maxLength={200}
                label="County"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.StateWide}
                component={ToggleField}
                maxLength={200}
                label="State Protocols"
                disabled={disabled}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.Enabled}
                component={ToggleField}
                maxLength={200}
                label="Enabled"
                disabled={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button loading={loading} disabled={pristine || submitting}>
                Save
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  if (!values[Fields.Name] || values[Fields.Name].length === 0) {
    errors[Fields.Name] = "Required";
  } else if (!values[Fields.State]) {
    errors[Fields.State] = "Required";
  }

  return errors;
};

const ManageAgency = reduxForm({
  validate,
  form: MANAGE_AGENCY_FORM,
  enableReinitialize: true,
})(ManageAgencyContainer);

export { ManageAgency };
