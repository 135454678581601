import React, { Component } from "react";
import { Menu } from "semantic-ui-react";

import EventManager from "./ContextMenuEventManager";
import { ContextMenuEvent } from "./ContextMenuManager";
import { ContextMenuItem } from "./ContextMenuItem";

const styles = {
  menu: {
    position: "fixed",
    zIndex: 99999,
  },
};

const initialState = {
  gridProps: null,
  options: [],
  open: false,
  top: 0,
  left: 0,
  target: null,
};

class ContextMenuDisplay extends Component {
  state = {
    ...initialState,
  };

  componentDidMount() {
    EventManager.on(ContextMenuEvent.SHOW_GRID_MENU, this.open).on(
      ContextMenuEvent.CLOSE_MENU,
      this.close,
    );
    document.addEventListener("click", this.close);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.close);

    EventManager.off(ContextMenuEvent.SHOW_GRID_MENU);
    EventManager.off(ContextMenuEvent.CLOSE_MENU);
  }

  updatePosition = (x, y) => {
    const menu = this.el.querySelector("[data-id=menu]");
    const menuHeight = menu.offsetHeight;
    const menuWidth = menu.offsetWidth;
    const windowHeight = window.innerHeight;

    this.setState({
      top: windowHeight - y < menuHeight ? y - menuHeight : y,
      left: x - menuWidth,
    });
  };

  open = ({ gridProps, options, target }) => {
    this.setState({ open: true, options, gridProps, target }, () => {
      target.classList.add("selected-cog");
      const rect = target.getBoundingClientRect();

      //uses the center of the cog as the spawn point for the menu
      this.updatePosition(rect.x + rect.width / 2, rect.y + rect.height / 2);
    });
  };

  close = () => {
    if (this.state.target) {
      this.state.target.classList.remove("selected-cog");
    }

    if (this.state.open) {
      this.setState({ ...initialState });
    }
  };

  render() {
    if (!this.state.open) {
      return null;
    }

    const { top, left, options, gridProps } = this.state;

    return (
      <div ref={(ref) => (this.el = ref)}>
        <Menu
          vertical
          className={"context-menu"}
          data-id={"menu"}
          style={{ ...styles.menu, top, left }}
        >
          {options.map((option) => {
            return (
              <ContextMenuItem
                key={option.text}
                item={option}
                gridProps={gridProps}
              />
            );
          })}
        </Menu>
      </div>
    );
  }
}

export { ContextMenuDisplay };
