import React, { Component, Fragment } from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { Button, Grid } from "semantic-ui-react";
import { caseInsensitiveFilter } from "../../../common/helpers/grid";
import { GridContextMenu } from "../../../common/contextmenu/ContextMenuManager";
import { YesNoDialog } from "../../../components/dialogs/YesNoDialog";

class ManageFilesContainer extends Component {
  state = { showDeleteModal: false, rowData: { id: 0, fileName: "" } };

  deleteFile = (row) => {
    this.setState({
      showDeleteModal: true,
      rowData: row,
    });
  };

  viewFile = (row) => {
    window.open(row.downloadUrl, "_Blank");
  };

  editFile = (row) => {
    this.props.setProtocolEdit(true, row);
  };

  addNewProtocol = () => {
    this.props.setProtocolNew(true);
  };

  handleDialog(result) {
    if (result) {
      this.props.deleteProtocol(this.props.agencyId, this.state.rowData.id);
    }

    this.setState({ showDeleteModal: false, rowData: { id: 0, fileName: "" } });
  }

  menuOptions = [
    {
      icon: "eye",
      text: "View",
      className: "danger",
      handler: this.viewFile,
    },
    ,
    {
      icon: "edit",
      text: "Edit",
      className: "danger",
      handler: this.editFile,
    },
    {
      icon: "trash",
      text: "Delete",
      className: "danger",
      handler: this.deleteFile,
    },
  ];

  columns = [
    {
      Header: "Name",
      accessor: "fileName", // String-based value accessors!
      style: { textAlign: "left" },
    },
    {
      Header: "State",
      accessor: "state",
      width: 75,
    },
    {
      Header: "Category",
      accessor: "category",
      style: { textAlign: "left" },
      width: 225,
    },
    {
      Header: "",
      id: "menu",
      width: 120,
      sortable: false,
      resizable: false,
      filterable: false,
      style: { textAlign: "center" },
      Cell: (gridProps) => {
        return GridContextMenu({ gridProps, options: this.menuOptions });
      },
    },
  ];

  render() {
    return (
      <Fragment>
        <Grid>
          <Grid.Row textAlign="right">
            <Grid.Column>
              <Button onClick={this.addNewProtocol}>Add Protocol</Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ReactTable
                data={this.props.data}
                defaultSorted={[{ id: "name", desc: true }]}
                className={"-striped -highlight grid--fixed-row-height"}
                defaultFilterMethod={caseInsensitiveFilter}
                columns={this.columns}
                minRows={0}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={15}
                noDataText="No rows found"
                loading={this.props.loading}
                filterable={true}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <YesNoDialog
          showDialog={this.state.showDeleteModal}
          title={`Delete`}
          question={` ${this.state.rowData.fileName}?`}
          yesCallback={() => {
            this.handleDialog(true);
          }}
          noCallback={() => {
            this.handleDialog(false);
          }}
        />
      </Fragment>
    );
  }
}

export const ManageFiles = ManageFilesContainer;
