import * as types from "./types";

const INITIAL_STATE = {
  agencies: null,
  error: null,
  fetching: false,
  agencyId: undefined,
  protocolEdit: false,
  protocolNew: false,
  protocolFiles: [],
  protocolEditRow: undefined,
};

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case types.MANAGEAGENCIES_GET_AGENCIES:
      return {
        ...state,
        agencies: payload,
      };

    case types.MANAGEAGENCIES_GET_AGENCIES_FILES:
      return { ...state, protocolFiles: payload };

    case types.MANAGEAGENCIES_SET_PROTOCOLEDIT:
      return {
        ...state,
        protocolEdit: payload.value,
        protocolEditRow: payload.row,
      };

    case types.MANAGEAGENCIES_SET_PROTOCOLNEW:
      return { ...state, protocolNew: payload };

    case types.MANAGEAGENCIES_ERROR:
      return { ...state, error: payload };

    case types.MANAGEAGENCIES_FETCHING:
      return { ...state, fetching: payload };

    case types.MANAGEAGENCIES_ERROR_CLEAR:
      return { ...state, error: payload };

    case types.MANAGEAGENCIES_SET_AGENCY_ID:
      return { ...state, agencyId: payload };

    default:
      return state;
  }
};
