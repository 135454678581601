import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { logOut } from "../../pages/login/state/actions";

class LogInOutButtonContainer extends Component {
  logOut = () => {
    this.props.logOut();
  };

  componentDidUpdate(prevProps) {
    const { history, authToken } = this.props;

    //previously had a token and now does not
    //redirect to home
    if (prevProps.authToken && !authToken) {
      history.push("/");
    }
  }

  render() {
    const { authToken } = this.props;

    if (authToken) {
      return (
        <Button inverted onClick={this.logOut}>
          Log out
        </Button>
      );
    }
    return (
      <Link to="/login">
        <Button inverted>Log in</Button>
      </Link>
    );
  }
}

const mapStateToProps = (state) => {
  return { authToken: state.LoginPageState.token };
};

export const LogInOutButton = withRouter(
  connect(mapStateToProps, { logOut })(LogInOutButtonContainer),
);
