import React from "react";
import { Route, Redirect } from "react-router-dom";
import store from "../store";
import { PAGES } from "../common/constants";

function PrivateRoute({ component: Component, ...rest }) {
  const state = store.getState();
  const authToken = state.LoginPageState.token;
  //https://stackoverflow.com/questions/61541563/private-route-with-redux

  return (
    <Route
      {...rest}
      render={(props) =>
        authToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/${PAGES.LOGIN}`,
              state: { referer: props.location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
