import React, { Component } from "react";
import { Button, Grid, Form } from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import { MANAGE_FILES_EDIT_FORM } from "../../../common/FormEnum";
import { ManageFilesEditEnum as Fields } from "../../../common/FieldEnum";
import {
  InputField,
  DropdownField,
} from "../../../common/SemanticReduxFormWrapper";
import { states as stateOptions } from "../../../common/constants";

class ManageFilesEditContainer extends Component {
  goBack = () => {
    this.props.setProtocolEdit(false);
  };

  render() {
    const { handleSubmit, loading, submitting, pristine } = this.props;

    return (
      <Form onSubmit={handleSubmit} name={MANAGE_FILES_EDIT_FORM}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.State}
                component={DropdownField}
                placeholder="Select a state"
                fluid
                search
                selection
                options={stateOptions}
                label="State"
                closeOnEscape
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={Fields.Category}
                component={InputField}
                // type="file"
                maxLength={200}
                label="Category"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {this.props.submitFailed && this.props.error}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column>
              <Button
                type="submit"
                loading={loading}
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button type="button" onClick={this.goBack}>
                Cancel
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  return errors;
};

const ManageFilesEdit = reduxForm({
  validate,
  form: MANAGE_FILES_EDIT_FORM,
  enableReinitialize: true,
})(ManageFilesEditContainer);

export { ManageFilesEdit };
