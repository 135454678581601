import firebase from "firebase/app";
import "firebase/auth";

import * as types from "./types";
import * as rootTypes from "../../../state/types";
import { getGenericWithToken } from "../../../api";
import { ROLES } from "../../../common/constants";

export const loginSuccess = (user, refreshToken, token) => async (dispatch) => {
  try {
    //token is added to local storage in the reducer upon successful login. The Api file uses local storage to get the token.
    //passing the token as a param for this one use case so we can check the role
    const response = await getGenericWithToken(`/api/users/${user.uid}`, token);
    const role = response.data?.user?.role;

    if ([ROLES.ADMIN, ROLES.MANAGER].indexOf(role) === -1) {
      if (!role) {
        //User tried to create an account
        //The UI being used doesn't allow you to block user creation
        const currUser = firebase.auth().currentUser;
        await currUser.delete();
      }

      await Promise.all([dispatch(logOut())]);

      dispatch({
        type: types.LOGIN_ERROR,
        payload:
          "Access denied! You are setup as a basic user that does not have access to the admin portal.  Contact your system administrator.",
      });

      return;
    }

    const agencyIds = response.data?.user?.agencyIds;
    dispatch({
      type: types.LOGIN_AUTH,
      payload: { user, refreshToken, token, agencyIds, role },
    });
  } catch (err) {
    console.log(err);
  }
};

export const clearError = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOGIN_ERROR_CLEAR, payload: null });
  } catch (e) {
    console.error("clearError", e);
  }
};

export const logOut = () => async (dispatch) => {
  try {
    await firebase.auth().signOut();
  } catch (e) {
    console.error("Sign Out Error", e);
  } finally {
    dispatch({ type: types.LOGIN_CLEAR });
    dispatch({ type: rootTypes.SIGNOUT_REQUEST });
  }
};
