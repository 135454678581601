import React, { Component } from "react";
import { Menu, Icon, Grid } from "semantic-ui-react";

class ContextMenuItem extends Component {
  onClick = () => {
    const { gridProps, item } = this.props;
    const { pageSize, page, index, original } = gridProps;

    if (gridProps) {
      const rowIndexWithPaging = pageSize * page + index;
      item.handler(original, rowIndexWithPaging);
    } else {
      item.handler(original);
    }
  };

  render() {
    const {
      item,
      gridProps: { original },
    } = this.props;

    if (!!(item.disabledWhen && item.disabledWhen(original))) {
      return null;
    }

    return (
      <Menu.Item className={item.className} onClick={this.onClick}>
        <Grid className={"context-menu--item-grid"}>
          <Grid.Row columns={2}>
            <Grid.Column width={2}>
              <Icon name={item.icon} />
            </Grid.Column>
            <Grid.Column width={13}>{item.text}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Menu.Item>
    );
  }
}

export { ContextMenuItem };
